import { render, staticRenderFns } from "./SupplierDebt.vue?vue&type=template&id=ca814b40&scoped=true&"
import script from "./SupplierDebt.vue?vue&type=script&lang=js&"
export * from "./SupplierDebt.vue?vue&type=script&lang=js&"
import style0 from "./SupplierDebt.vue?vue&type=style&index=0&id=ca814b40&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca814b40",
  null
  
)

export default component.exports