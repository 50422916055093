<template>
  <CRow class="mb-5">
    <CCol lg="5" xl="5" xxl="4">
      <TAside class="card">
        <CListGroup>
          <CListGroupItem>
            <CRow>
              <CCol col="6">
                <TMessage content="From date" bold class="mb-2" />
                <TInputDateTime
                  :value.sync="filter.start"
                  timeline="start"
                  class="mb-2"
                  placeholder="Start date"
                  :key="startDateKey"
                />
              </CCol>
              <CCol col="6">
                <TMessage content="To date" bold class="mb-2" />
                <TInputDateTime
                  :value.sync="filter.end"
                  timeline="end"
                  class="mb-2"
                  placeholder="End date"
                  :key="endDateKey"
                />
              </CCol>
              <CCol col="12">
                <SSelectSupplier
                  :value.sync="filter.object_id"
                  prependAll
                  label="Supplier"
                  class="mb-2"
                />
              </CCol>
              <CCol col="12">
                <TButton
                  class="w-100 mt-2"
                  content="Filter"
                  @click="onFilter"
                  :disabled="objects_fetching || !filter.end || !filter.start"
                />
              </CCol>
            </CRow>
          </CListGroupItem>
          <CListGroupItem
            v-for="(payable, index) in objects"
            :key="`${index}`"
            :color="
              selected.object_id_selected == payable.walletable_id &&
              selected.currency_id == payable.currency_id
                ? 'primary'
                : 'secondary'
            "
            :to="
              !references_fetching
                ? _.getAppendRouteQuery($route, {
                    object_id_selected: payable.walletable_id,
                    currency_id: payable.currency_id,
                  })
                : ''
            "
          >
            <div class="d-flex">
              <SMessageWallet :data="payable" />
              <TMessageMoney
                :amount="payable.balance"
                class="ml-auto"
                :currency="payable.currency_id"
              />
            </div>
          </CListGroupItem>
          <CListGroupItem v-if="objects_fetching">
            <TSpinner :loading="objects_fetching" />
          </CListGroupItem>
          <CListGroupItem v-if="!objects.length">
            <TMessageNotFound />
          </CListGroupItem>
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="7" xl="7" xxl="8">
      <TTableSimple
        v-if="selected.object_id_selected"
        :items="data || []"
        columnFilter
        :options="{ sorter: true }"
        class="table-custom"
        :fields="fields"
        size="lg"
        :loading="references_fetching"
      >
        <template #_="{ item }">
          <td>
            <TButton
              icon="cil-eye"
              tooltip="View"
              color="primary"
              size="sm"
              variant="outline"
              @click="viewReceipt(item)"
            />
          </td>
        </template>
        <template #receiptable_type="{ item }">
          <td>
            <TMessage
              v-if="item.receiptable_type == 'purchase'"
              content="Purchase order"
            />
            <TMessage
              v-else
              :content="
                lodash.upperFirst(lodash.lowerCase(item.receiptable_type))
              "
            />
          </td>
        </template>
        <template #receiptable_id="{ item }">
          <td>
            <TMessage :content="item.receiptable_id" noTranslate />
          </td>
        </template>
        <template #paid="{ item }">
          <td>
            <TMessageMoney :amount="item.paid" :currency="item.currency_id" />
          </td>
        </template>
        <template #debited="{ item }">
          <td>
            <TMessageMoney
              :amount="item.debited"
              :currency="item.currency_id"
            />
          </td>
        </template>
        <template #outstanding="{ item }">
          <td>
            <TMessageMoney
              :amount="item.outstanding"
              :currency="item.currency_id"
            />
          </td>
        </template>
        <template #result-outstanding>
          <th>
            <div class="d-flex">
              <TMessage content="Total:" />
              <i>
                <TMessageMoney
                  :amount="getTotal('outstanding')"
                  :currency="selected.currency_id"
                  class="ml-1"
                />
              </i>
            </div>
          </th>
        </template>
        <template #result-debited>
          <th>
            <div class="d-flex">
              <TMessage content="Total:" />
              <i>
                <TMessageMoney
                  :amount="getTotal('debited')"
                  :currency="selected.currency_id"
                  class="ml-1"
                />
              </i>
            </div>
          </th>
        </template>
        <template #result-paid>
          <th>
            <div class="d-flex">
              <TMessage content="Total:" />
              <i>
                <TMessageMoney
                  :amount="getTotal('paid')"
                  :currency="selected.currency_id"
                  class="ml-1"
                />
              </i>
            </div>
          </th>
        </template>
        <template #receiptable_type-filter>
          <TSelect
            class="m-0"
            :options="receiptableType"
            :value="receiptableSelected"
            :selectOptions="{
              internalSearch: true,
              preserveSearch: true,
            }"
            :normalizer="{ label: 'name', value: 'id' }"
            placeholder="Select"
            @select="receiptableSelected = $event"
          />
        </template>
        <template #receiptable_id-filter>
          <TInputText placeholder="Voucher code" :value.sync="receptableId" />
        </template>
        <template #_-filter>
          <TButtonClear
            variant="outline"
            class="mb-1 ml-2"
            @click="clearFilter"
          />
        </template>
      </TTableSimple>
    </CCol>
    <ModalTransactions
      v-if="showDetailVocherModal"
      :show.sync="showDetailVocherModal"
      :voucher="voucher_selected"
      :default_filter="{
        'filter_between[voucher_date]': `${filter.start},${filter.end}`,
      }"
      @updated="fetchDetail()"
    />
  </CRow>
</template>

<script>
import ModalTransactions from "../../components/ModalTransactions";
import debtMixin from "../../mixins/debtMixin";

export default {
  components: { ModalTransactions },
  mixins: [debtMixin],
  data() {
    return {
      fields: [
        {
          key: "_",
          label: "#",
          showCount: true,
          countLabel: "Count",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "receiptable_type",
          label: "Receipts type",
          _classes: "text-nowrap",
          _style: "width: 160px; min-width: 160px",
        },
        {
          key: "receiptable_id",
          label: "Voucher",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "debited",
          label: "Debited",
          sorter: true,
          _style: "width: 180px; min-width: 180px",
        },
        {
          key: "paid",
          label: "Paid",
          sorter: true,
          _style: "width: 180px; min-width: 180px",
        },
        {
          key: "outstanding",
          label: "Outstanding",
          sorter: true,
          _style: "width: 180px; min-width: 180px",
        },
      ],
      receiptableType: [
        { id: "", name: this.$t("All") },
        { id: "container", name: this.$t("Container") },
        {
          id: "purchase",
          name: this.$t("Purchase order"),
        },
      ],
      object_type: "supplier",
    };
  },
  methods: {
    getTotal(key) {
      return Math.round(this.lodash.sumBy(this.data, key) * 100) / 100 || 0;
    },
  },
};
</script>

<style scoped>
.table-custom >>> .table-responsive {
  min-height: 200px;
}
</style>
